import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 查询停车场信息
export function getParkingList(params) {
    return request.post('/operation/parkingLotList?' + objectToQueryString(params));
}

// 禁用/启用车场
export function updateParkingLotStatus(params){
    return request.post('/operation/delParkingLot?' + objectToQueryString(params));
}

// 查询泊位列表
export function getParkingSpaceList(params){
    return request.post('/operation/parkingSpaceList?' + objectToQueryString(params));
}

// 编辑泊位
export function editParkingSpace(params){
    return request.post('/operation/editParkingSpace?' + objectToQueryString(params));
}
