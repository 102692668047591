<template>
  <div v-loading="isLoading" class="padding18 border-radius4" style="background: white;">
    <easy-card :title="'车场:'+parkingName">
      <!-- <icon-button icon="add-select" slot="extra" @click.native="addEntry" /> -->
      <el-table :data="parkingspaceList" :key="itemKey" class="common-table" style="width: 100%">
        <el-table-column
            label="泊位号"
            prop="parkingSpaceCode"
            width="100"
        ></el-table-column>
        <el-table-column label="系统编号" prop="systemCode">
        </el-table-column>
        <el-table-column label="自定义编号" prop="customizeCode">
          <template slot-scope="scope">
            <el-input
                @change="changedItem(scope.row)"
                v-model="scope.row.customizeCode"
                placeholder="自定义编号"/>
          </template>
        </el-table-column>
        <el-table-column label="设备编号" prop="deviceCode">
          <template slot-scope="scope">
            <el-input
                @change="changedItem(scope.row)"
                v-model="scope.row.deviceCode"
                placeholder="设备编号"
            />
          </template>
        </el-table-column>
        <el-table-column label="经纬度" prop="parkingLocation">
          <template slot-scope="scope">
<!--            <Location-->
<!--                v-model="scope.row"-->
<!--                @makesure="onLocationMakeSure($event,scope.row)"-->
<!--            />-->
            <el-input
                @change="changedItem(scope.row)"
                :ref="'parkingLocation_'+scope.row.systemCode"
                v-model="scope.row.parkingLocation"
                placeholder="车场经纬度"
                readonly="readonly"
                @focus="showModal(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.haveChanged"
                class="common-text font-size14"
                size="small"
                type="text"
                @click="goSave(scope.row)"
            >保存
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </easy-card>
    <el-dialog
        :append-to-body="true"
        :visible.sync="dialogVisible"
        class="common-dialog"
        title="获取经纬度"
    >
      <div>
        <el-autocomplete
            v-model="keyword"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            clearable
            placeholder="根据地址关键字搜索"
            popper-class="autoAddressClass"
            class="width300 margin-right10"
            @select="handleSelect"
        >
          <template slot-scope="{ item }">
            <div class="padding-top5" style="overflow: hidden;">
              <div
                  class="title font-size16 margin-top5"
                  style="line-height: 1;"
              >
                {{ item.title }}
              </div>
              <span
                  class="address ellipsis font-size12"
                  style="color: #999"
              >{{ item.address }}</span
              >
            </div>
          </template>
        </el-autocomplete>
        <el-input
            :value="point.lng"
            disabled
            placeholder="经度"
            class="margin-left10 width110"
        />
        <el-input
            :value="point.lat"
            disabled
            placeholder="纬度"
            class="margin-left10 width110"
        />
      </div>
      <div id="map-container" class="map-container"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doMakeSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getParkingSpaceList,
  editParkingSpace,
} from "@/api/operation";
// import Location from "@/components/Location.vue";
import BaseMixin from "@/mixins/base";
// import Permission from "@/mixins/permission";

export default {
  name: "passage",
  mixins: [BaseMixin],
  // components: {Location},
  props: {
    parkingId: String,
    parkingName: String,
  },
  rules: {
    customizeCode: [
      {required: true, message: "自定义编号不能为空"},
      {min: 3, message: "自定义编号至少三个字符"}
    ],
  },
  data() {
    return {
      itemKey: 0,
      dialogVisible: false,
      map: null,
      mk: null,
      keyword: "",
      point: {},
      focusedParkingLocation: "",
      focusedItem:null,
      // parkingId: "",
      parkingspaceList: [],
      out: [],
      // entryNumber: 0,
      // outNumber: 0,
      isLoading: false,
      entrySnCodeNumber: 100,
      outSnCodeNumber: 100,
      pagination: {
        pageNo:1,
        pageSize: 10,
        totalSize: 0,
        totalPages: 1
      },
    };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initMap();
        });
      }
    },
  },
  methods: {
    changedItem(item){
      if(item.parkingSpaceCode){
        item.haveChanged = true
        this.$nextTick(()=>{
          this.itemKey++;
        })
      }
    },
    getAddrByPoint(point) {
      const that = this;
      const geco = new window.BMap.Geocoder();
      const convertor = new window.BMap.Convertor();
      geco.getLocation(point, function (res) {
        that.mk.setPosition(point);
        that.map.panTo(point);
        that.keyword = res.address;
        console.log("百度地图坐标系：", point);
        convertor.translate([point], 5, 3, function (data) {
          if (data.status === 0) {
            console.log("转换后的坐标系：", data.points[0]);
            that.point = data.points[0];
          }
        });
        // that.point = point;
      });
    },
    initMap() {
      const that = this;
      const BMap = window.BMap;
      this.map = new BMap.Map('map-container', {enableMapClick: false});
      var point = new BMap.Point(103.27, 25.56);
      if(this.focusedParkingLocation!==''){
        point = new BMap.Point(this.focusedParkingLocation.split(',')[0],this.focusedParkingLocation.split(',')[1])
      }
      this.map.centerAndZoom(point, 14);

      // 3、设置图像标注并绑定拖拽标注结束后事件
      this.mk = new BMap.Marker(point, {enableDragging: true});
      this.map.addOverlay(this.mk);
      this.mk.addEventListener("dragend", function (e) {
        that.getAddrByPoint(e.point);
      });

      this.map.addEventListener("click", function (e) {
        that.getAddrByPoint(e.point);
      });
    },
    doMakeSure() {
      // parkingspaceList
      // this.$refs['parkingLocation_'+this.focusedItem.systemCode].v
      console.log(this.focusedItem.systemCode)
      console.log(this.parkingspaceList.filter(s => s.systemCode === this.focusedItem.systemCode)[0])
      this.parkingspaceList.filter(s => s.systemCode === this.focusedItem.systemCode)[0].parkingLocation = this.point.lng + "," + this.point.lat

      // if (JSON.stringify(this.point) === "{}") {
      //   this.$emit("makesure", "");
      // } else {
      //   this.parkingLocation = this.point.lng + "," + this.point.lat;
      //   this.$emit("makesure", this.point.lng + "," + this.point.lat);
      // }
      this.dialogVisible = false;
    },
    handleSelect(item) {
      const that = this;
      const point = item.point;

      this.keyword = item.address + item.title;
      this.map.clearOverlays();
      this.mk = new window.BMap.Marker(point);
      this.map.addOverlay(this.mk);
      this.map.panTo(point);

      // 将百度地图坐标系转换为GCJO2坐标系
      const convertor = new window.BMap.Convertor();
      convertor.translate([point], 5, 3, function (data) {
        if (data.status === 0) {
          that.point = data.points[0];
        }
      });
    },
    querySearchAsync(str, cb) {
      var options = {
        onSearchComplete: function (res) {
          var s = []; //  == BMAP_STATUS_SUCCESS
          if (local.getStatus() === 0) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i));
            }
            cb(s);
          } else {
            cb(s);
          }
        },
      };
      var local = new window.BMap.LocalSearch(this.map, options);
      local.search(str);
    },
    showModal(item) {
      console.log(item.systemCode)
      this.focusedItem = item
      this.focusedParkingLocation = item.parkingLocation
      this.dialogVisible = true;
    },
    onLocationMakeSure(point,item) {
      console.log(point,item)
      var posItem = this.parkingspaceList.filter(s => s.parkingSpaceId === item.parkingSpaceId)[0]
      posItem.parkingLocation = point.split(',')[0]+','+ point.split(',')[1];
      if(point && point.split(',').length===2){
        posItem.longitude = point.split(',')[0]
        posItem.latitude = point.split(',')[1]
      }
    },
    search() {
      this.entry = [];
      this.out = [];

      if(this.parkingId===''){
        return false
      }

      this.isLoading = true;
      Promise.all([
        getParkingSpaceList({parkingLotId: this.parkingId, pageNo: this.pagination.pageNo, pageSize: this.pagination.pageSize}),
      ])
          .then((resList) => {
            this.isLoading = false;
            const res = resList[0];
            if (res.code === 30 && res.result) {
              // parkingEntryNum = getNumberRes.returnObject.entryNumber;
              // parkingOutNum = getNumberRes.returnObject.exportNumber;
              var returnObject = res.returnObject
              returnObject.records.forEach( r =>
                  r.parkingLocation = r.longitude + "," + r.latitude
              )
              this.parkingspaceList = returnObject.records
              this.pagination.totalSize = returnObject.total;
              this.pagination.totalPages = returnObject.pages;
            }
          })
          .catch((e) => console.log(e));
    },
    goSave(row){
      console.log(row)
      console.log(this.parkingspaceList.filter(s => s.systemCode === row.systemCode)[0])
      if(row.customizeCode.length<3){
        this.$message.error('自定义编号至少三个字符')
        return false
      }
      row.longitude = row.parkingLocation.split(',')[0]
      row.latitude = row.parkingLocation.split(',')[1]
      editParkingSpace(row).then(res =>{
        if (res.code === 30 && res.result) {
          this.$message.success('泊位编辑成功')
          this.$nextTick(()=>{
            row.haveChanged = false
            this.itemKey++
          })
        }
      })
    },
    addEntry() {
      // 先判断数组长度 数组长度为0 => 101
      // 不为0 101 + 1 = 102
      //
      if (this.entry.length === 0) {
        this.entrySnCodeNumber = 100;
      } else {
        let snCodeNumber = this.entry[this.entry.length - 1].snCode;
        let number = snCodeNumber.substring(3);
        this.entrySnCodeNumber = parseInt(number);
      }

      this.entry.push({
        id: "",
        snCode: "IN-" + (this.entrySnCodeNumber + 1),
        // this.entry.length >= 10
        //   ? "IN-1" + (this.entry.length + 1)
        //   : "IN-1" + "0" + (this.entry.length + 1),
        deviceType: "1",
        ipAddress: "",
        cameraAccount: "",
        cameraPassword: "",
        name: "",
      });
    },
    addOut() {
      if (this.out.length === 0) {
        this.outSnCodeNumber = 100;
      } else {
        let snCodeNumber = this.out[this.out.length - 1].snCode;
        let number = snCodeNumber.substring(4);
        this.outSnCodeNumber = parseInt(number);
      }

      this.out.push({
        id: "",
        snCode: "OUT-" + (this.outSnCodeNumber + 1),
        // this.out.length >= 10
        //   ? "OUT-1" + (this.out.length + 1)
        //   : "OUT-1" + "0" + (this.out.length + 1),
        deviceType: "2",
        ipAddress: "",
        cameraAccount: "",
        cameraPassword: "",
        name: "",
      });
    },
  },
  mounted() {
    if (this.parkingId) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    }
  },
};
</script>

<style lang="less" scoped>
.map-container {
  height: 500px;
  margin-top: 10px;
  border: 1px solid #ddd;
}
</style>
